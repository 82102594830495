<template>
  <div>
    <div
      class="text-center"
      v-if="isLoading"
    >
      <v-progress-circular
        indeterminate
        size="24"
      ></v-progress-circular>
    </div>
    <div v-if="!isLoading">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h3 class="pb-5">Данные дома</h3>
            <Address
              :addresses.sync="detail.buildingAddresses"
              :mainImage="mainImage"
            />
            <v-card
              outlined
              class="mt-1 pa-2"
            >
              <v-card-text>
                <BuildingInfo
                  :builder.sync="detail.builder"
                  :district-custom.sync="detail.district_custom"
                  :district-official.sync="detail.district_official"
                  :subdistrict.sync="detail.subdistrict"
                  :building-material.sync="detail.building_material"
                  :build-year.sync="detail.build_year"
                  :building-type.sync="detail.building_type"
                  :total-floors.sync="detail.total_floors"
                  :total-apartments.sync="detail.total_apartments"
                  :building-serial.sync="detail.building_serial"
                  :front-doors-count.sync="detail.front_doors_count"
                  :overhaul.sync="detail.overhaul"
                  :apartment-complex.sync="detail.apartment_complex"
                  :manager-company.sync="detail.manager_company"
                  :description-service.sync="detail.description_service"
                  :description-entrance.sync="detail.description_entrance"
                  :is-ready.sync="detail.is_ready"
                  :build-overlap.sync="detail.build_overlap"
                />
                <v-row>
                  <ImageUploader
                    title="Фото дома"
                    v-model="detail.buildingImages"
                    url="/image/building"
                  />
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div class="text-right save-block">
        <v-btn
          class="mr-2"
          dark
          color="light-green"
          small
          :loading="isSavingData"
          @click="saveDetail"
          :disabled="!canSave"
        >
          <v-icon class="pr-3">mdi-content-save</v-icon>
          Сохранить
        </v-btn>
        <v-btn
          v-if="value.requestParams.id"
          class="mr-2"
          dark
          small
          @click.stop="handleSafeDelete"
          :disabled="!$can('agent', 'removeBuilding')"
        >
          <v-icon class="pr-3">mdi-delete</v-icon>
          Удалить дом
        </v-btn>
        <SafeDelete
          v-if="value.requestParams.id"
          :confirm-delete.sync="confirmDelete"
          :can-delete="canDelete"
          :filter="filter"
          category="advert"
          :checking-delete="checkingDelete"
          @delete="deleteDetail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import detail from '@/components/mixins/detail'
import Address from '@/components/views/admin/building/Detail/Address'
import BuildingInfo from '@/components/views/admin/building/Detail/BuildingInfo'
import ImageUploader from '@/components/views/admin/common/ImageUploader'
import SafeDelete from '@/components/views/admin/common/SafeDelete'

export default {
  mixins: [detail],
  name: 'Detail',
  components: { BuildingInfo, Address, Autocomplete, ImageUploader, SafeDelete },
  data () {
    return {
      componentCategory: 'building',
      confirmDelete: false
    }
  },
  computed: {
    mainImage () {
      let mainImage = null
      if (this.detail.buildingImages.length) {
        mainImage = this.detail.buildingImages.find(image => image.is_main)
      }
      return mainImage
    },
    filter () {
      const address = this.detail.buildingAddresses[0]
      return {
        buildingNumberFilter: {
          id: address.id,
          building_id: this.value.requestParams.id,
          building_number: address.building_number
        },
        streetFilter: {
          id: address.street.id,
          name: address.street.name
        }
      }
    },
    canSave () {
      let result = false
      if (this.detail.id) {
        result = this.$can('agent', 'editBuilding')
      } else {
        result = this.$can('agent', 'createBuilding')
      }
      return result
    }
  },
  methods: {
    async hasErrors () {
      let canSave = false
      if (!this.detail.buildingAddresses[0].street) {
        canSave = 'Укажите адрес'
      }
      return canSave
    }
  }
}
</script>

<style scoped>

</style>
